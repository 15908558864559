import React from "react";
import SpotlightUser from "./SpotlightUser";
import styled from "styled-components";

const SpotlightUsers = ({ spotlightData, activeTab, fetchSpotlightUsers }) => {
  return (
    <Container>
      <SpotlightUser
        user={spotlightData.spotlightUsers[activeTab]}
        onDataUpdate={fetchSpotlightUsers}
      />
    </Container>
  );
};

export default SpotlightUsers;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 62px); // 헤더 높이만큼 제외
  padding: 20px;
`;
