import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaYoutube } from "react-icons/fa";

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 62px);
  padding: 20px;
  text-align: center;
  background-color: #fff;
`;

const Title = styled.h1`
  font-size: 4rem;
  background: linear-gradient(45deg, #ff416b, #ff4b2d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(255, 75, 45, 0.2);

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.4rem;
  color: #666;
  margin-bottom: 3rem;
  max-width: 800px;
  line-height: 1.8;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
    padding: 0 15px;
  }
`;

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  background: linear-gradient(45deg, #ff416b, #ff4b2d);
  color: white;
  border: none;
  padding: 16px 48px;
  border-radius: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 65, 107, 0.3);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(255, 65, 107, 0.4);
  }

  @media (max-width: 480px) {
    padding: 14px 36px;
    font-size: 1.1rem;
  }
`;

const FeatureSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin-top: 5rem;
  padding: 0 20px;

  @media (max-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const FeatureCard = styled.div`
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(255, 65, 107, 0.15);
  }

  h3 {
    color: #ff416b;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }

  p {
    color: #666;
    line-height: 1.6;
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    padding: 2rem;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const YoutubeIcon = styled(FaYoutube)`
  font-size: 1.5rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 3rem;
`;

const GuestButton = styled(LoginButton)`
  background: white;
  color: #ff416b;
  border: 2px solid #ff416b;

  &:hover {
    background: #ff416b;
    color: white;
  }
`;

function Landing() {
  const navigate = useNavigate();

  const handleLogin = () => {
    localStorage.setItem("visited", "true");
    navigate("/login");
  };

  const handleGuestAccess = () => {
    localStorage.setItem("visited", "true");

    navigate("/"); // 또는 비로그인 사용자를 위한 적절한 경로
  };

  return (
    <LandingContainer>
      <Title>Channel Matching</Title>
      <Subtitle>
        혹시 나와 비슷한 유튜브 취향을 가진 친구가 있을까요? 🤔
        <br />
        당신의 유튜브 취향을 함께 공유하며 새로운 인연을 발견해보세요!
      </Subtitle>
      <ButtonGroup>
        <LoginButton onClick={handleLogin}>
          <YoutubeIcon />
          채널매칭 시작하기
        </LoginButton>
        <GuestButton onClick={handleGuestAccess}>
          비로그인으로 둘러보기
        </GuestButton>
      </ButtonGroup>
      <FeatureSection>
        <FeatureCard>
          <h3>나만의 유튜브 성향 분석</h3>
          <p>
            "이 채널도 좋아하시나요?" 내가 몰랐던 나의 유튜브 취향, 지금 바로
            확인해보세요! ✨
          </p>
        </FeatureCard>
        <FeatureCard>
          <h3>찐친 매칭</h3>
          <p>
            어라? 이 사람도 이 채널 좋아하네! 취향이 똑 닮은 친구를 발견하는
            신기한 경험을 시작해보세요 🎯
          </p>
        </FeatureCard>
        <FeatureCard>
          <h3>함께라서 더 즐거워요</h3>
          <p>
            혼자 보기 아까웠던 그 채널, 이제는 함께 공유하며 더 큰 재미를
            발견해보세요! ✨
          </p>
        </FeatureCard>
      </FeatureSection>
    </LandingContainer>
  );
}

export default Landing;
