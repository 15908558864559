import React from "react";
import Logopng from "../assets/img/logo_ChannelMatching.png";
import styled from "styled-components";

export default function Logo() {
  const mainUrl = process.env.REACT_APP_MAIN_URL;

  return (
    <div>
      <a href={mainUrl} title="메인 페이지로 이동">
        <Logoimg src={Logopng} alt="로고" />
      </a>
    </div>
  );
}

const Logoimg = styled.img`
  /* border: 1px solid blue; */

  height: 60px;
  margin-left: 10px;
`;
