import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
`;

const Title = styled.h2`
  font-size: 40px;
  margin: 0;
  color: #333;
`;

const SubscriptionInfo = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #666;

  strong {
    color: #4285f4;
    font-size: 24px;
    margin: 0 5px;
  }
`;

const ChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 20px;
  padding: 20px 0;
`;

const ChannelItem = styled.div`
  position: relative;
  cursor: pointer;

  &:hover .channel-name {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ChannelImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ChannelName = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

// 임시 데이터
const tempSubscriptions = [
  {
    channelId: 1,
    title: "코딩채널",
    thumbnailUrl: "https://picsum.photos/200",
  },
  {
    channelId: 2,
    title: "요리채널",
    thumbnailUrl: "https://picsum.photos/201",
  },
  {
    channelId: 3,
    title: "여행채널",
    thumbnailUrl: "https://picsum.photos/202",
  },
  {
    channelId: 4,
    title: "음악채널",
    thumbnailUrl: "https://picsum.photos/203",
  },
  {
    channelId: 5,
    title: "게임채널",
    thumbnailUrl: "https://picsum.photos/204",
  },
  {
    channelId: 6,
    title: "영화리뷰",
    thumbnailUrl: "https://picsum.photos/205",
  },
  { channelId: 7, title: "북튜버", thumbnailUrl: "https://picsum.photos/206" },
  { channelId: 8, title: "IT뉴스", thumbnailUrl: "https://picsum.photos/207" },
  { channelId: 9, title: "스포츠", thumbnailUrl: "https://picsum.photos/208" },
  {
    channelId: 10,
    title: "애니메이션",
    thumbnailUrl: "https://picsum.photos/209",
  },
  {
    channelId: 11,
    title: "과학채널",
    thumbnailUrl: "https://picsum.photos/210",
  },
  {
    channelId: 12,
    title: "역사채널",
    thumbnailUrl: "https://picsum.photos/211",
  },
  {
    channelId: 13,
    title: "언어학습",
    thumbnailUrl: "https://picsum.photos/212",
  },
  {
    channelId: 14,
    title: "건강/운동",
    thumbnailUrl: "https://picsum.photos/213",
  },
  {
    channelId: 15,
    title: "뷰티채널",
    thumbnailUrl: "https://picsum.photos/214",
  },
  {
    channelId: 16,
    title: "패션채널",
    thumbnailUrl: "https://picsum.photos/215",
  },
  {
    channelId: 17,
    title: "반려동물",
    thumbnailUrl: "https://picsum.photos/216",
  },
  {
    channelId: 18,
    title: "DIY crafts",
    thumbnailUrl: "https://picsum.photos/217",
  },
  {
    channelId: 19,
    title: "테크리뷰",
    thumbnailUrl: "https://picsum.photos/218",
  },
  {
    channelId: 20,
    title: "음식리뷰",
    thumbnailUrl: "https://picsum.photos/219",
  },
  {
    channelId: 21,
    title: "일상브이로그",
    thumbnailUrl: "https://picsum.photos/220",
  },
  {
    channelId: 22,
    title: "교육채널",
    thumbnailUrl: "https://picsum.photos/221",
  },
  {
    channelId: 23,
    title: "투자/재테크",
    thumbnailUrl: "https://picsum.photos/222",
  },
  {
    channelId: 24,
    title: "예술채널",
    thumbnailUrl: "https://picsum.photos/223",
  },
  {
    channelId: 25,
    title: "댄스채널",
    thumbnailUrl: "https://picsum.photos/224",
  },
  {
    channelId: 26,
    title: "자동차리뷰",
    thumbnailUrl: "https://picsum.photos/225",
  },
  {
    channelId: 27,
    title: "인테리어",
    thumbnailUrl: "https://picsum.photos/226",
  },
  {
    channelId: 28,
    title: "gardening",
    thumbnailUrl: "https://picsum.photos/227",
  },
  {
    channelId: 29,
    title: "명상/힐링",
    thumbnailUrl: "https://picsum.photos/228",
  },
  {
    channelId: 30,
    title: "드론영상",
    thumbnailUrl: "https://picsum.photos/229",
  },
  {
    channelId: 31,
    title: "축구채널",
    thumbnailUrl: "https://picsum.photos/230",
  },
  {
    channelId: 32,
    title: "농구채널",
    thumbnailUrl: "https://picsum.photos/231",
  },
  {
    channelId: 33,
    title: "야구채널",
    thumbnailUrl: "https://picsum.photos/232",
  },
  {
    channelId: 34,
    title: "골프채널",
    thumbnailUrl: "https://picsum.photos/233",
  },
  {
    channelId: 35,
    title: "웹툰채널",
    thumbnailUrl: "https://picsum.photos/234",
  },
];

function PublicSubscriptionList() {
  return (
    <Container>
      <Row>
        <Title>미리보기 구독 채널</Title>
      </Row>
      {tempSubscriptions.length === 0 ? (
        <SubscriptionInfo>공개된 구독 채널이 없습니다.</SubscriptionInfo>
      ) : (
        <>
          <SubscriptionInfo>
            총 <strong>{tempSubscriptions.length}</strong>개의 공개 구독 채널이
            있어요! 🎉
          </SubscriptionInfo>
          <ChannelGrid>
            {tempSubscriptions.map((subscription) => (
              <ChannelItem key={subscription.channelId}>
                <ChannelImage>
                  <img
                    src={subscription.thumbnailUrl}
                    alt={subscription.title}
                  />
                </ChannelImage>
                <ChannelName className="channel-name">
                  {subscription.title}
                </ChannelName>
              </ChannelItem>
            ))}
          </ChannelGrid>
        </>
      )}
    </Container>
  );
}

export default PublicSubscriptionList;
