import React from "react";
import { Tabs, Tab } from "@mui/material";

const tempCompareUsers = [
  {
    uuid: "user1",
    displayName: "김철수",
  },
];

function PublicTabNavigation({ activeTab, setActiveTab }) {
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        mb: 3,
      }}
    >
      <Tab label="구독 목록" value="mySubscriptions" />
      {tempCompareUsers.map((user, index) => (
        <Tab
          key={user.uuid}
          label={`${user.displayName}님과의 공통 구독`}
          value={`commonSubscriptions-${index}`}
        />
      ))}
    </Tabs>
  );
}

export default PublicTabNavigation;
