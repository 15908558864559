import React from "react";
import styled from "styled-components";

const SurveyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledIframe = styled.iframe`
  width: 100%;
  max-width: 640px;
  height: 2700px;
  border: none;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 4000px;
  }
`;

const Survey = () => {
  return (
    <SurveyContainer>
      <StyledIframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScItQ1WC8Lj3RTLBJrFedGmW2suDz2_HWVJl8qZGWZL8gNYzw/viewform?embedded=true"
        title="채널매칭 설문조사"
      >
        로드 중...
      </StyledIframe>
    </SurveyContainer>
  );
};

export default Survey;
