// components/Spotlight.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import SpotlightIntro from "./SpotlightIntro";

function Spotlight() {
  return (
    <SpotlightContainer>
      <Routes>
        <Route path="/" element={<SpotlightIntro />} />
      </Routes>
    </SpotlightContainer>
  );
}

export default Spotlight;

const SpotlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 62px); // 헤더 높이만큼 제외
  padding: 20px;
`;
