import React from "react";
import styled from "styled-components";
import "./Login.css";
const PublicComparisonModal = ({ compareUser, closeModal }) => {
  const API_URL = `${process.env.REACT_APP_API_URL}`;

  const handleGoogleLogin = () => {
    window.location.href = `${API_URL}/auth/google`;
  };
  return (
    <Modal onClick={closeModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <>
          <h2>{compareUser.displayName}님과의 공통 구독</h2>
          <p>
            로그인 후 {compareUser.displayName}님과 <br />
            공통으로 구독중인 채널을 찾아보세요!
          </p>
          <ModalButton onClick={handleGoogleLogin}>
            <i className="fab fa-google" />
            Login with Google
          </ModalButton>
        </>
      </ModalContent>
    </Modal>
  );
};

export default PublicComparisonModal;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ModalButton = styled.button`
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 18px 40px;
  border-radius: 20px;
  font-size: 20px;
  margin-top: 1rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  &:hover {
    background-color: #3367d6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
  }
  i {
    border-right: 2px solid white;
    margin-right: 10px;
    padding-right: 10px;
  }
`;
