import React from "react";
import { Avatar, Typography, Box } from "@mui/material";

// 임시 사용자 데이터
const tempUser = {
  displayName: "홍길동",
  email: "hong@example.com",
  image: "https://picsum.photos/200",
};

function PublicUserInfo() {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Avatar
        src={tempUser?.image || ""}
        alt={tempUser?.displayName || "User"}
        sx={{
          width: 60,
          height: 60,
          mr: 2,
          border: "2px solid #e0e0e0", // 아바타에 테두리 추가
        }}
      />
      <Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 500,
            color: "#333",
          }}
        >
          {tempUser?.displayName || "사용자"}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            mt: 0.5,
          }}
        >
          {tempUser?.email || ""}
        </Typography>
      </Box>
    </Box>
  );
}

export default PublicUserInfo;
