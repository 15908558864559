// AdminSpotlightPage.js
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const AdminTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #f2f2f2;
  padding: 12px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

const ApproveButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
`;

const AdminSpotlightPage = () => {
  const [applications, setApplications] = useState([]);
  const API_URL = `${process.env.REACT_APP_API_URL}`;
  const navigate = useNavigate();

  const fetchApplications = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/spotlight/applications`,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        }
      );

      setApplications(response.data);
    } catch (error) {
      console.error("Error fetching applications:", error);
      if (error.response && error.response.status === 401) {
        // 401 Unauthorized 에러 발생 시 메인 페이지로 리다이렉트
        navigate("/");
      }
    }
  }, [API_URL, navigate]);

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);

  const handleApprove = async (app) => {
    try {
      await axios.put(
        `${API_URL}/api/spotlight/approve`,
        { app },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        }
      );
      fetchApplications();
    } catch (error) {
      console.error("Error approving application:", error);
    }
  };

  const handleterminateSpotlight = async (app) => {
    try {
      await axios.post(
        `${API_URL}/api/spotlight/terminate`,
        { app },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        }
      );
      fetchApplications();
    } catch (error) {
      console.error("Error approving application:", error);
    }
  };

  return (
    <div>
      <h2>스포트라이트 신청 관리</h2>
      <AdminTable>
        <thead>
          <tr>
            <TableHeader>사용자 ID</TableHeader>
            <TableHeader>신청일</TableHeader>
            <TableHeader>상태</TableHeader>
            <TableHeader>액션</TableHeader>
          </tr>
        </thead>
        <tbody>
          {applications.map((app) => (
            <tr key={app._id}>
              <TableCell>{app.user}</TableCell>
              <TableCell>
                {new Date(app.appliedAt).toLocaleDateString()}
              </TableCell>
              <TableCell>{app.status}</TableCell>
              <TableCell>
                {app.status === "pending" && (
                  <ApproveButton onClick={() => handleApprove(app)}>
                    승인
                  </ApproveButton>
                )}
                {app.status === "approved" && (
                  <ApproveButton onClick={() => handleterminateSpotlight(app)}>
                    종료
                  </ApproveButton>
                )}
                {app.status === "terminate" && app.status}
              </TableCell>
            </tr>
          ))}
        </tbody>
      </AdminTable>
    </div>
  );
};

export default AdminSpotlightPage;
