import React, { useState } from "react";

import "./Login.css"; // CSS 파일을 별도로 만들어야 합니다
import "@fortawesome/fontawesome-free/css/all.css";

function Login() {
  const [isRightPanelActive, setIsRightPanelActive] = useState(false);

  const API_URL = `${process.env.REACT_APP_API_URL}`;

  const handleGoogleLogin = () => {
    window.location.href = `${API_URL}/auth/google`;
  };

  const MobileView = () => (
    <div className="mobile-login-container">
      <div className="mobile-login-header">
        <h1>Channel Matching</h1>
      </div>
      <div className="mobile-login-content">
        <div className="login-subtitle">구글 계정으로 시작하기</div>
        <div className="login-text">구글 아이디로 간편하게 로그인하세요</div>
        <button onClick={handleGoogleLogin} className="mobile-login-button">
          <i className="fab fa-google" />
          Login with Google
        </button>
      </div>
    </div>
  );
  return (
    <>
      <div className="login-page">
        <div
          className={`container ${isRightPanelActive ? "right-panel-active" : ""}`}
          id="container"
        >
          <div className="form-container sign-up-container">
            <form className="login-form" onSubmit={(e) => e.preventDefault()}>
              <div className="service-info-container">
                <div className="service-info-title">채널매칭 서비스 안내</div>
                <div className="service-info-description">
                  채널매칭 서비스는 YouTube 구독 리스트를 활용하여 사용자 간의
                  공통 관심사를 찾아주는 서비스입니다.
                </div>
                <div className="service-info-description">
                  서비스 이용을 위해서는 Google 계정 인증이 필요하며, YouTube
                  Data API의 readonly 권한을 통해 구독 리스트에만 접근합니다.
                </div>
                <div className="service-links">
                  <a
                    className="service-link"
                    href="https://sites.google.com/view/channelmatching/%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    이용약관
                  </a>
                  <a
                    className="service-link"
                    href="https://sites.google.com/view/channelmatching/%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    개인정보처리방침
                  </a>
                </div>
                <div className="service-info-thanks">
                  채널매칭에 관심을 가져주셔서 진심으로 감사드립니다. 여러분의
                  소중한 관심을 바탕으로 더 나은 서비스로 발전하도록 하겠습니다.
                </div>
                <div className="service-info-copyright">
                  © 2024 채널매칭. All rights reserved.
                </div>
              </div>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form className="login-form" onSubmit={(e) => e.preventDefault()}>
              <div className="login-subtitle">채널매칭 서비스 안내</div>
              <div className="login-text">
                YouTube 구독 정보 수집 및 개인정보 처리방침에 대해 확인하세요
              </div>
              <button
                type="button"
                className="login-button login-button-ghost1"
                id="signUp"
                onClick={() => setIsRightPanelActive(true)}
              >
                서비스 정보 보기
              </button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <div className="login-subtitle">구글 계정으로 시작하기</div>
                <div className="login-text">
                  구글 아이디로 간편하게 로그인하세요
                </div>
                <button
                  className="login-button login-button-ghost"
                  id="signIn"
                  onClick={() => setIsRightPanelActive(false)}
                >
                  Sign In / Up
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <div
                  className="login-title"
                  style={{
                    fontSize: "36px",
                    marginBottom: "30px",
                    color: "white",
                  }}
                >
                  ChannelMatching
                </div>
                <div
                  className="login-title"
                  style={{
                    marginBottom: "40px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Sign In / Sign Up
                </div>
                <button
                  onClick={handleGoogleLogin}
                  className="google-login-button"
                >
                  <i className="fab fa-google" />
                  Login with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileView />
    </>
  );
}

export default Login;
