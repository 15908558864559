import React from "react";
import { Box, Button, Snackbar, Typography, Paper } from "@mui/material";
import { FaGift } from "react-icons/fa";

function ShareLink({ shareLink, user, open, setOpen }) {
  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setOpen(true);
  };

  if (!user) {
    return null;
  }

  return (
    <Paper
      sx={{
        textAlign: "center",
        borderRadius: "12px",
        padding: "24px",
        margin: "20px 0",
        boxShadow:
          "0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.14), 1px 8px 8px 0px rgba(0, 0, 0, 0.12)",
        background: "linear-gradient(135deg, #fff5f5 0%, #fff 100%)",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "linear-gradient(45deg, rgba(255,217,217,0.3) 0%, rgba(255,255,255,0) 100%)",
          borderRadius: "12px",
          zIndex: 0,
        },
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Typography
          variant="h6"
          sx={{
            color: "#d32f2f",
            textAlign: "center",
          }}
        >
          🎄 메리 크리스마스! 🎄
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "rgba(0, 0, 0, 0.87)",
            textAlign: "center",
          }}
        >
          친구와 함께 유튜브 채널 취향을 공유해보세요!
        </Typography>

        <Button
          variant="contained"
          onClick={handleCopy}
          startIcon={<FaGift />}
          sx={{
            borderRadius: "50px",
            padding: "8px 24px",
            backgroundColor: "#d32f2f",
            "&:hover": {
              backgroundColor: "#9a0007",
            },
          }}
        >
          크리스마스 초대장 보내기
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="🎅 크리스마스 초대장이 복사되었습니다!"
      />
    </Paper>
  );
}

export default ShareLink;
