// components/SpotlightIntro.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const SpotlightIntro = () => {
  const [isApplying, setIsApplying] = useState(false);
  const [message, setMessage] = useState("");
  const [hasApplied, setHasApplied] = useState(false);

  useEffect(() => {
    checkApplicationStatus();
  }, []);

  const checkApplicationStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/spotlight/status`,
        { withCredentials: true }
      );
      setHasApplied(response.data.hasApplied);
    } catch (error) {}
  };

  const handleApply = async () => {
    setIsApplying(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/spotlight/apply`,
        {},
        { withCredentials: true }
      );
      setMessage(response.data.message);
      setHasApplied(true);
    } catch (error) {
      if (error.response.data.message === "인증되지 않은 사용자입니다.") {
        setMessage("로그인이 필요한 서비스 입니다.");
      } else {
        setMessage(
          error.response.data.message || "신청 중 오류가 발생했습니다."
        );
      }
    } finally {
      setIsApplying(false);
    }
  };

  return (
    <Container>
      <Title>스포트라이트</Title>
      <Description>
        스포트라이트 서비스는 여러분의 유튜브 구독 채널을 다른 모든 사용자와
        매칭할 수 있는 특별한 기회를 제공합니다. 스포트라이트 롤을 받으면
        사이트의 모든 사용자와 채널 매칭을 할 수 있으며, 여러분의 관심사를
        공유하는 새로운 친구들을 만날 수 있습니다.
      </Description>
      <FeatureList>
        <FeatureItem>모든 사용자와 채널 매칭 가능 🎯</FeatureItem>
        <FeatureItem>매칭율과 공통 채널 수 확인</FeatureItem>
        <FeatureItem>전체 매칭 수와 순위 확인</FeatureItem>
        <FeatureItem>개인 유튜브 채널 홍보 기회 ✨</FeatureItem>
        <FeatureItem>"어머! 당신도 이 채널 좋아하시나요?"</FeatureItem>
        <FeatureItem>"유튜버 팬이시군요! 저도 팬이에요!"</FeatureItem>
      </FeatureList>

      {!hasApplied ? (
        <ApplyButton onClick={handleApply} disabled={isApplying}>
          {isApplying ? "신청 중..." : "스포트라이트 신청하기"}
        </ApplyButton>
      ) : (
        <AppliedMessage>
          승인 대기 중 입니다.
          <br />
          이미 스포트라이트를 신청하셨습니다.
        </AppliedMessage>
      )}
      {message && <Message>{message}</Message>}
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
  text-align: center;
  background: linear-gradient(to bottom, #ffffff, #fff5f5);
  box-shadow: 0 4px 6px rgba(255, 65, 107, 0.1);
  border-radius: 15px;

  @media (max-width: 768px) {
    padding: 40px 15px;
    margin: 0 15px;
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, #ff416b, #ff4b2d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
  letter-spacing: -0.5px;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 4px;
    background: linear-gradient(45deg, #ff416b, #ff4b2d);
    margin: 20px auto;
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 20px;

    &:after {
      width: 60px;
      margin: 15px auto;
    }
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #666;
  line-height: 1.8;
  margin-bottom: 50px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
    padding: 0 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 0 10px;
  }
`;
const FeatureList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  padding: 0;
  margin: 50px auto;
  max-width: 1200px;
  list-style: none;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
    margin: 30px auto;
  }
`;

const FeatureItem = styled.li`
  font-size: 1.1rem;
  color: #666;
  padding: 25px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(255, 65, 107, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "✓";
    color: #ff416b;
    margin-right: 12px;
    font-size: 1.2rem;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(255, 65, 107, 0.15);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 20px;

    &:before {
      font-size: 1.1rem;
    }
  }
`;

const ApplyButton = styled.button`
  background: linear-gradient(45deg, #ff416b, #ff4b2d);
  color: white;
  border: none;
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 65, 107, 0.3);
  min-width: 250px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(255, 65, 107, 0.4);
  }

  &:disabled {
    background: linear-gradient(45deg, #cccccc, #bbbbbb);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    padding: 12px 30px;
    font-size: 1.1rem;
    min-width: 200px;
  }

  @media (max-width: 480px) {
    padding: 10px 25px;
    font-size: 1rem;
    min-width: 180px;
  }
`;

const AppliedMessage = styled.p`
  font-size: 1.2rem;
  color: #ff416b;
  font-weight: 600;
  padding: 20px;
  background: rgba(255, 65, 107, 0.1);
  border-radius: 10px;
  margin: 30px auto;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 15px;
    margin: 20px auto;
    max-width: 90%;
  }
`;

const Message = styled.p`
  margin-top: 25px;
  font-size: 1.1rem;
  padding: 15px 30px;
  border-radius: 8px;
  background: ${(props) =>
    props.error ? "rgba(244, 67, 54, 0.1)" : "rgba(255, 65, 107, 0.1)"};
  color: ${(props) => (props.error ? "#f44336" : "#ff416b")};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 12px 20px;
    margin-top: 20px;
    max-width: 90%;
  }
`;

export default SpotlightIntro;
