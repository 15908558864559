import React, { useState, useEffect, useCallback } from "react";
import { NavLink, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import Spotlight from "./Spotlight";
import SpotlightUsers from "./SpotlightUsers";
import Survey from "./Survey";
import PublicDashboard from "./PublicDashboard";

const API_URL = `${process.env.REACT_APP_API_URL}`;

function PublicLayout() {
  const [spotlightData, setSpotlightData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const fetchSpotlightUsers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/spotlight/spotlight-users`,
        {
          withCredentials: true,
        }
      );
      setSpotlightData(response.data);
    } catch (error) {
      console.error("스포트라이트 사용자 데이터 가져오기 실패:", error);
    }
  }, []);

  useEffect(() => {
    fetchSpotlightUsers();
  }, [fetchSpotlightUsers]);

  if (!spotlightData) return <Container>로딩 중...</Container>;

  return (
    <LayoutContainer>
      <Sidebar>
        <SpotlightLink to="/" end>
          스포트라이트
        </SpotlightLink>
        <SidebarSpotlight>
          {spotlightData.spotlightUsers.map((user, index) => (
            <SpotlightLink
              key={user.userInfo.uuid}
              to={`/users/${user.userInfo.uuid}`}
              className={activeTab === index ? "active" : ""}
              onClick={(e) => {
                setActiveTab(index);
                if (
                  window.location.pathname === `/users/${user.userInfo.uuid}`
                ) {
                  e.preventDefault();
                }
              }}
            >
              {user.userInfo.displayName}
            </SpotlightLink>
          ))}
        </SidebarSpotlight>
        <StyledNavLink to="/my-channel">내 채널</StyledNavLink>
        <SpotlightLink to="/survey">1분만에 하는 설문조사</SpotlightLink>
      </Sidebar>
      <Content>
        <Routes>
          <Route path="/" element={<Spotlight />} />
          <Route path="/my-channel" element={<PublicDashboard />} />
          <Route
            path="/users/:userId"
            element={
              <SpotlightUsers
                spotlightData={spotlightData}
                activeTab={activeTab}
                fetchSpotlightUsers={fetchSpotlightUsers}
              />
            }
          />
          <Route path="/survey" element={<Survey />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Content>
    </LayoutContainer>
  );
}

export default PublicLayout;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const Sidebar = styled.div`
  background-color: #2c3e50;
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  @media (min-width: 769px) {
    width: 200px;
    height: 100%;
  }
`;
const SidebarSpotlight = styled.div`
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border-radius: 4px;
  /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
  @media (min-width: 769px) {
    width: 200px;
    height: 100%;
  }
`;
const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 62px); // 헤더 높이만큼 제외
  padding: 20px;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  padding: 12px 16px;
  color: #ecf0f1;
  text-decoration: none;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #34495e;
  }

  &.active {
    background-color: #3498db;
    font-weight: bold;
  }
`;

const SpotlightLink = styled(StyledNavLink)`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /* Add specific active state styling */
  &.active {
    background-color: #3498db;
    font-weight: bold;
  }

  /* Only highlight the clicked link */
  &[aria-current] {
    background-color: #3498db;
  }

  /* Reset other links when one is active */
  &:not([aria-current]) {
    background-color: transparent;
  }
`;
