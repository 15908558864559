import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";

const UserContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const UserName = styled.span`
  font-weight: bold;
`;

const LoginButton = styled.button`
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  width: 80px;
  margin-right: 10px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  min-width: 200px;
  z-index: 1000;
  margin-top: 8px;
`;

const MenuItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const User = ({ isAuthenticated, user, shareLink }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClick = () => {
    if (localStorage.getItem("visited") === null) {
      localStorage.setItem("visited", "true");
    }
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  const handleLogout = () => {
    // 로그아웃 로직 구현
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/logout`;
    setShowDropdown(false);
    navigate("/login");
  };

  if (!isAuthenticated) {
    return <LoginButton onClick={handleClick}>로그인</LoginButton>;
  }

  return (
    <UserContainer ref={dropdownRef}>
      <div onClick={handleClick}>
        {user.image ? (
          <UserImage src={user.image} alt={user.name} />
        ) : (
          <UserName>{user.name}</UserName>
        )}
      </div>

      {showDropdown && (
        <DropdownMenu>
          <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
        </DropdownMenu>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={`초대링크가 복사되었습니다!(${shareLink})`}
      />
    </UserContainer>
  );
};

export default User;
