import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import PublicComparisonModal from "./PublicComparisonModal";
import PublicSubscriptionList from "./PublicSubscriptionList";
import PublicUserInfo from "./PublicUserInfo";
import PublicTabNavigation from "./PublicTabNavigation";
import PublicCommonSubscriptions from "./PublicCommonSubscriptions";
import CircularProgress from "@mui/material/CircularProgress";
const API_URL = `${process.env.REACT_APP_API_URL}`;

// 임시 비교 사용자 데이터

export default function PublicDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [compareUser, setCompareUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("mySubscriptions");
  useEffect(() => {
    const fetchData = async () => {
      const compareUUID = localStorage.getItem("compare");
      if (compareUUID) {
        try {
          const response = await axios.get(
            `${API_URL}/auth/user/${compareUUID}`,
            {
              withCredentials: true,
            }
          );
          setCompareUser(response.data);
          setShowModal(true);
        } catch (error) {
          console.error(
            "비교 대상 사용자 정보를 가져오는데 실패했습니다:",
            error
          );
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const closeModal = () => {
    setShowModal(false);
  };
  if (isLoading) {
    return (
      <DashboardContainer2>
        <CircularProgress />
      </DashboardContainer2>
    );
  }
  return (
    <DashboardContainer>
      {" "}
      {showModal && compareUser && (
        <PublicComparisonModal
          compareUser={compareUser}
          closeModal={closeModal}
        />
      )}
      <ContentSection>
        <Row>
          <PublicUserInfo />
        </Row>
        <TabSection>
          <PublicTabNavigation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </TabSection>
        {activeTab === "mySubscriptions" && <PublicSubscriptionList />}
        {activeTab.startsWith("commonSubscriptions") && (
          <PublicCommonSubscriptions />
        )}
      </ContentSection>
    </DashboardContainer>
  );
}

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 62px); // 헤더 높이만큼 제외
  padding: 20px;
`;
const DashboardContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 62px); // 헤더 높이만큼 제외
  padding: 20px;
`;
const ContentSection = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const TabSection = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
