import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
`;

const MatchInfo = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;

  strong {
    color: #4285f4;
    font-size: 24px;
    margin: 0 5px;
  }
`;

const ChannelGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 20px;
  padding: 20px 0;
`;

const ChannelItem = styled.div`
  position: relative;
  cursor: pointer;

  &:hover .channel-name {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ChannelImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const ChannelName = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
`;

const AdditionalInfo = styled.div`
  margin-top: 30px;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
`;

const InfoItem = styled.p`
  color: #666;
  margin: 8px 0;
  font-size: 16px;
`;

function PublicCommonSubscriptions() {
  // 임시 데이터로 현재 비교 정보 설정
  const compareUsers = [{ uuid: "user1", displayName: "김철수" }];
  const currentComparison = {
    matchingRate: 10.5,
    commonCount: 10,
    subscriptions: [
      {
        channelId: 1,
        title: "코딩채널",
        thumbnailUrl: "https://picsum.photos/200",
      },
      {
        channelId: 2,
        title: "요리채널",
        thumbnailUrl: "https://picsum.photos/201",
      },
      {
        channelId: 3,
        title: "여행채널",
        thumbnailUrl: "https://picsum.photos/202",
      },

      {
        channelId: 6,
        title: "영화리뷰",
        thumbnailUrl: "https://picsum.photos/205",
      },
      {
        channelId: 7,
        title: "북튜버",
        thumbnailUrl: "https://picsum.photos/206",
      },
      {
        channelId: 8,
        title: "IT뉴스",
        thumbnailUrl: "https://picsum.photos/207",
      },
      {
        channelId: 32,
        title: "농구채널",
        thumbnailUrl: "https://picsum.photos/231",
      },
      {
        channelId: 33,
        title: "야구채널",
        thumbnailUrl: "https://picsum.photos/232",
      },
      {
        channelId: 34,
        title: "골프채널",
        thumbnailUrl: "https://picsum.photos/233",
      },
      {
        channelId: 35,
        title: "웹툰채널",
        thumbnailUrl: "https://picsum.photos/234",
      },
    ],
  };

  const compareUser = compareUsers[0];

  if (!currentComparison) {
    return <Container>선택된 비교 정보가 없습니다.</Container>;
  }

  return (
    <Container>
      <Title>{compareUser?.displayName}님과의 공통 구독 채널</Title>
      <MatchInfo>
        와우! {compareUser?.displayName}님과 당신은
        <strong>{currentComparison.matchingRate.toFixed(1)}%</strong>의 취향이
        일치해요! 🎉
      </MatchInfo>

      <ChannelGrid>
        {currentComparison.subscriptions.map((subscription) => (
          <ChannelItem key={subscription.channelId}>
            <ChannelImage>
              <img src={subscription.thumbnailUrl} alt={subscription.title} />
            </ChannelImage>
            <ChannelName className="channel-name">
              {subscription.title}
            </ChannelName>
          </ChannelItem>
        ))}
      </ChannelGrid>

      <AdditionalInfo>
        <InfoItem>공통 구독 채널: {currentComparison.commonCount}개</InfoItem>
        <InfoItem>{compareUser?.displayName}님의 최근 구독: 70개</InfoItem>
        <InfoItem>내 최근 구독: 35개</InfoItem>
        <InfoItem>비교 구독 채널: 95개</InfoItem>
      </AdditionalInfo>
    </Container>
  );
}

export default PublicCommonSubscriptions;
