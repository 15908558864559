import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";

const API_URL = `${process.env.REACT_APP_API_URL}`;

const SpotlightUser = ({ user, onDataUpdate }) => {
  const [matchData, setMatchData] = useState(null);
  const [error, setError] = useState(null);
  const [topRankedUsers, setTopRankedUsers] = useState(null);

  const fetchMatchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/spotlight/match/${user.userInfo.uuid}`,
        {
          withCredentials: true,
        }
      );

      if (response.data.success) {
        setMatchData(response.data.data);
        setError(null);
      } else {
        setMatchData(null);
        setError({
          status: response.data.status || null,
          message:
            response.data.message || "매칭 데이터를 가져오는데 실패했습니다.",
        });
      }
    } catch (error) {
      setMatchData(null);
      if (error.status === 401) {
        setError({
          message: "인증되지 않은 사용자입니다.",
        });
      } else {
        setError({
          message: "서버와의 통신 중 오류가 발생했습니다.",
        });
      }
    }
  }, [user.userInfo.uuid]);

  const fetchTopRankedUsers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/spotlight/top-ranked-users/${user.userInfo.uuid}`,
        {
          withCredentials: true,
        }
      );
      setTopRankedUsers(response.data);
    } catch (error) {
      console.error("상위 랭킹 사용자 조회 오류:", error);
    }
  }, [user.userInfo.uuid]);

  useEffect(() => {
    fetchMatchData();
    fetchTopRankedUsers();
  }, [fetchMatchData, fetchTopRankedUsers]);

  const handleMatch = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/spotlight/match/${user.userInfo.uuid}`,
        {},
        { withCredentials: true }
      );
      if (response.data.success) {
        await fetchMatchData();
        if (onDataUpdate) {
          onDataUpdate();
        }
      } else {
        setError({
          message: response.data.message || "매칭에 실패했습니다.",
        });
      }
    } catch (error) {
      setError({
        message: "서버와의 통신 중 오류가 발생했습니다.",
      });
    }
  };

  const renderContent = () => {
    if (error) {
      switch (error.message) {
        case "인증되지 않은 사용자입니다.":
          return <ErrorMessage>로그인 후 매칭가능 합니다.</ErrorMessage>;
        case "스포트라이트 신청자 본인입니다":
          return <ErrorMessage>⚠️ 스포트라이트 신청자 본인입니다</ErrorMessage>;
        case "사용자를 찾을 수 없습니다.":
          return <ErrorMessage>❌ 사용자를 찾을 수 없습니다.</ErrorMessage>;
        case "매칭 결과를 찾을 수 없습니다.":
          return <MatchButton onClick={handleMatch}>매칭하기</MatchButton>;
        case "서버 오류가 발생했습니다.":
          return (
            <ErrorMessage>
              🔥 서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.
            </ErrorMessage>
          );
        default:
          return (
            <ErrorMessage>
              🌐 서버와의 통신 중 오류가 발생했습니다. 인터넷 연결을
              확인해주세요.
            </ErrorMessage>
          );
      }
    }

    if (matchData) {
      return (
        <MatchResult>
          <h3>매칭 결과</h3>
          <StatValue>매칭률: {matchData.matchingRate.toFixed(2)}%</StatValue>
          <StatValue>공통 채널 수: {matchData.commonChannels}</StatValue>
          <h3>매칭된 채널</h3>
          <MatchedChannels>
            {matchData.matchedChannels &&
            matchData.matchedChannels.length > 0 ? (
              matchData.matchedChannels.map((channel) => (
                <ChannelItem key={channel.channelId}>
                  <ChannelThumbnail
                    src={channel.thumbnailUrl}
                    alt={channel.title}
                  />
                  <ChannelTitle>{channel.title}</ChannelTitle>
                </ChannelItem>
              ))
            ) : (
              <p>매칭된 채널이 없습니다.</p>
            )}
          </MatchedChannels>
        </MatchResult>
      );
    }

    return <MatchButton onClick={handleMatch}>매칭하기</MatchButton>;
  };

  return (
    <UserContainer>
      <UserInfo>
        <Avatar src={user.userInfo.image} alt={user.userInfo.displayName} />
        <Name>{user.userInfo.displayName}</Name>
        <Email>{user.userInfo.email}</Email>
      </UserInfo>
      {user.channelInfo && user.channelInfo.customUrl && (
        <ChannelInfoContainer>
          <ChannelLink
            href={`https://youtube.com/${user.channelInfo.customUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ChannelThumbnail
              src={user.channelInfo.thumbnailUrl}
              alt={user.channelInfo.title}
            />
            <ChannelDetails>
              <ChannelTitle2>{user.channelInfo.title}</ChannelTitle2>
              <SubscriberCount>
                구독자 {user.channelInfo.subscriberCount.toLocaleString()}명
              </SubscriberCount>
            </ChannelDetails>
          </ChannelLink>
          {user.channelInfo.description && (
            <ChannelDescription>
              {user.channelInfo.description}
            </ChannelDescription>
          )}
        </ChannelInfoContainer>
      )}

      <ContentContainer>
        <StatsContainer>
          <StatItem>
            <StatValue>{user.stats.totalMatches}</StatValue>
            <StatLabel>총 매칭</StatLabel>
          </StatItem>
          <StatItem>
            <StatValue>{user.stats.highestMatchingRate.toFixed(2)}%</StatValue>
            <StatLabel>최고 매칭률</StatLabel>
          </StatItem>
          <StatItem>
            <StatValue>{user.stats.highestCommonChannels}</StatValue>
            <StatLabel>최다 공통 채널</StatLabel>
          </StatItem>
        </StatsContainer>
        <RankingContainer>
          <RankingTitle>상위 매칭 랭킹</RankingTitle>
          {topRankedUsers && topRankedUsers.length > 0 ? (
            topRankedUsers.map((rankedUser) => (
              <RankItem key={rankedUser.rank}>
                {rankedUser.rank}. {rankedUser.displayName} - 공통 채널 수:{" "}
                {rankedUser.commonCount}, 매칭률:{" "}
                {rankedUser.matchingRate.toFixed(2)}%
              </RankItem>
            ))
          ) : (
            <NoRankingInfo>랭킹 정보가 없습니다.</NoRankingInfo>
          )}
        </RankingContainer>
        {renderContent()}
      </ContentContainer>
    </UserContainer>
  );
};

const UserContainer = styled.div`
  padding: 20px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  @media (min-width: 480px) {
    max-width: 450px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 1024px) {
    max-width: 1200px;
    display: grid;
    grid-template-areas:
      "info content"
      "channel content";
    grid-template-columns: 350px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 30px;
  }
`;

const UserInfo = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border-radius: 8px;
  color: white;
  transition: transform 0.3s ease;
  height: 300px;

  @media (min-width: 768px) {
    height: 350px;
  }

  @media (min-width: 1024px) {
    height: 400px;
    &:hover {
      transform: scale(1.02);
    }
  }
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  @media (min-width: 768px) {
    width: 150px;
    height: 150px;
  }

  @media (min-width: 1024px) {
    width: 200px;
    height: 200px;
    &:hover {
      transform: rotate(5deg);
    }
  }
`;

const Name = styled.h2`
  font-size: 20px;
  margin: 10px 0 5px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  @media (min-width: 768px) {
    font-size: 22px;
  }

  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const Email = styled.p`
  font-size: 14px;
  margin: 5px 0;
  opacity: 0.8;

  @media (min-width: 768px) {
    font-size: 15px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: 768px) {
    gap: 20px;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    gap: 15px;
    padding: 20px;
  }

  @media (min-width: 1024px) {
    gap: 20px;
  }
`;

const StatItem = styled.div`
  text-align: center;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    height: 100px;
    padding: 15px;
  }

  @media (min-width: 1024px) {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
`;

const StatValue = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #4285f4;

  @media (min-width: 768px) {
    font-size: 22px;
  }

  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const StatLabel = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 5px;

  @media (min-width: 768px) {
    font-size: 13px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;

const RankingContainer = styled.div`
  margin-bottom: 15px;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

const RankingTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 19px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

const RankItem = styled.p`
  font-size: 12px;
  color: #666;
  margin: 5px 0;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  height: 40px;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    font-size: 13px;
    padding: 10px;
    height: 45px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
    height: 50px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const NoRankingInfo = styled.p`
  font-size: 12px;
  color: #666;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 13px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;

const MatchButton = styled.button`
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(66, 133, 244, 0.3);

  @media (min-width: 768px) {
    padding: 14px 28px;
    font-size: 17px;
  }

  @media (min-width: 1024px) {
    padding: 15px 30px;
    font-size: 18px;
    &:hover {
      background-color: #3367d6;
      transform: translateY(-2px);
      box-shadow: 0 6px 8px rgba(66, 133, 244, 0.4);
    }
  }
`;

const MatchResult = styled.div`
  margin-top: 15px;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  @media (min-width: 768px) {
    margin-top: 18px;
    padding: 18px;
  }

  @media (min-width: 1024px) {
    margin-top: 20px;
    padding: 20px;
    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const MatchedChannels = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 15px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin-top: 18px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-top: 20px;
  }
`;

const ChannelItem = styled.div`
  text-align: center;
  transition: transform 0.3s ease;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    height: 140px;
  }

  @media (min-width: 1024px) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const ChannelThumbnail = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
    margin-bottom: 9px;
  }

  @media (min-width: 1024px) {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
`;

const ChannelTitle = styled.span`
  font-size: 12px;
  color: #333;
  display: block;
  word-break: break-word;

  @media (min-width: 768px) {
    font-size: 13px;
  }

  @media (min-width: 1024px) {
    font-size: 14px;
  }
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  margin-top: 8px;
  padding: 12px;
  background-color: #ffebee;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    margin-top: 9px;
    padding: 13px;
    font-size: 15px;
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
    padding: 15px;
    font-size: 16px;
  }
`;
const ChannelInfoContainer = styled.div`
  grid-area: channel;
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  @media (min-width: 1024px) {
    margin-top: 0;
    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const ChannelDescription = styled.p`
  font-size: 13px;
  color: #666;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #eee;
  line-height: 1.4;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const ChannelLink = styled.a`
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  color: inherit;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
  }
`;

const ChannelDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ChannelTitle2 = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #1a73e8;
  font-weight: 600;
`;

const SubscriberCount = styled.span`
  font-size: 14px;
  color: #666;
`;
export default SpotlightUser;
